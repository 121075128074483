<template>
  <div class="add-admin">
    <h1 class="admin-title">{{$t('New Admin')}}</h1>
    <ValidationObserver ref="validator">
      <form class="add-admin-form" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <RFValidation rules="required|email" name="Email">
              <RFInput label="Email" type="email" v-model="admin.email"/>
            </RFValidation>
          </b-col>
          <b-col cols="12">
            <RFValidation rules="required" name="Firstname">
              <RFInput label="Firstname" v-model="admin.firstname"/>
            </RFValidation>
          </b-col>
          <b-col cols="12">
            <RFValidation rules="required" name="Lastname">
              <RFInput label="Lastname" v-model="admin.lastname"/>
            </RFValidation>
          </b-col>
          <b-col cols="12">
            <RFValidation rules="required|minLength|password" name="Password">
              <RFInput label="Password" type="password" v-model="admin.adminPassword" />
            </RFValidation>
          </b-col>
          <b-col cols="12">
            <RFValidation rules="required" name="Confirm Password">
              <RFInput label="Confirm Password" type="password" v-model="admin.adminConfirmPassword"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import {adminService} from '@/services/admin'
import {httpStatusCodes} from "@/enums";

export default {
  name: 'AdminAdd',
  components: {
    RFInput,
    RFButton,
    RFLoader,
    RFValidation,
  },
  data() {
    return {
      admin: {
        email: null,
        firstname: null,
        lastname: null,
        adminPassword: null,
        adminConfirmPassword: null
      },
      loading: false,
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        if (this.admin.adminPassword !== this.admin.adminConfirmPassword) {
          this.$toasted.error(this.$i18n.t(`Password and Confirm Password doesn't match`))
          return
        }
        this.loading = true

        const response = await adminService.addAdmin(this.admin)

        if (response && response.status === httpStatusCodes.SUCCESS) {
          this.$toasted.success(this.$i18n.t(`ADMIN user successfully added!`))
          this.$router.push({ name: 'admin' })
        } else {
          this.$toasted.error(this.$i18n.t(`Unable to create ADMIN user with this mail: ${this.admin.email} `))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
